//
//
//
//
//
//
//
//
//
//
//
//

import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
export default {
  mixins: [ModalMixin],
  data() {
    return {
      review: null,
      type: null,
      loading: false,
      onComplete: null
    };
  },
  mounted() {},
  methods: {
    onOpen({
      onComplete,
      type,
      review
    }) {
      this.review = {
        ...review
      };
      this.type = type;
      this.onComplete = onComplete;
    },
    async submit({
      close
    }) {
      this.loading = true;
      try {
        let result = {};
        if (this.type === "single") {
          result = await this.$store.dispatch("reviewManagement/updateAnswer", {
            id: this.review.answer_id,
            answer: this.review.answer
          });
        }
        this.loading = false;
        close();
        this.onComplete && this.onComplete(result);
        this.$emit("complete", result);
      } catch (e) {
        this.loading = false;
      }
    }
  },
  computed: {
    actions() {
      return ["cancel", {
        name: "submit",
        text: "一切正常, 我们正在发送",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        loading: this.loading
      }];
    }
  },
  components: {
    Modal
  }
};