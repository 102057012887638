//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalRemoveScript from "@/components/modals/ModalRemoveReviewManagementScript.vue";
import ModalTurnOnConfirm from "@/components/modals/ModalReviewManagementScriptTurnOnConfirm.vue";
import DataTable from "@/components/DataTable/DataTable";
import SwitchInput from "@/components/SwitchInput.vue";
export default {
  components: {
    DataTable,
    SwitchInput,
    ModalRemoveScript,
    ModalTurnOnConfirm
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    reportSettings: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      required: false
    },
    paginatable: {
      type: Boolean,
      required: false
    },
    configurable: {
      type: Boolean,
      required: false
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      prev: null,
      total: null
    };
  },
  methods: {
    refresh() {
      this.$refs.table.refreshSilent();
    },
    async tableLoader({
      query
    }) {
      let result = await this.$store.dispatch("reviewManagement/getScripts", {
        ...query,
        ...this.reportSettings
      });
      this.$emit("updateTotal", result.meta.total);
      this.total = result.meta.total;
      result = {
        items: [...result.data],
        total: result.meta.total,
        success: result.success,
        message: result.message
      };
      return result;
    },
    async changeStatus(id, data) {
      if (data !== null && data !== void 0 && data.value) {
        this.$refs.modalTurnOnConfirm.open({
          onComplete: async () => {
            data.confirm();
          }
        });
      } else {
        await this.$store.dispatch("reviewManagement/toggleScript", id);
      }
    }
  },
  computed: {
    columns() {
      return [{
        title: "商店状态",
        position: 1,
        show: true,
        name: "status",
        width: 50,
        sort: false,
        type: "slot"
      }, {
        title: "商店名称",
        position: 2,
        show: true,
        name: "name",
        width: 300
      }, {
        title: "派遣",
        position: 3,
        show: true,
        name: "approval_required",
        type: "slot",
        width: 160
      }, {
        title: "最后回复日期",
        position: 4,
        show: true,
        name: "last_answer",
        type: "datetimeLocal",
        width: 160
      }, {
        title: "回复数量",
        position: 5,
        show: true,
        name: "answers_count",
        width: 160
      }, {
        title: "店铺",
        position: 6,
        show: true,
        name: "token",
        width: 160
      }, {
        title: "",
        position: 7,
        show: true,
        name: "_actions",
        type: "actions",
        actions: [{
          title: "删除",
          action: (item, {
            refresh
          }) => {
            this.$refs.modalRemoveScript.open({
              item,
              onComplete() {
                refresh();
              }
            });
          }
        }, {
          title: "编辑",
          action: item => {
            this.$router.push({
              name: "ReviewManagementScript",
              params: {
                id: item.id
              }
            });
          }
        }],
        width: 40
      }];
    }
  }
};